import { Link } from '@reach/router';
import React from 'react';
import { useSelector } from 'react-redux';
import { Collection } from 'shopify-storefront-api-typings';
import styled, { css } from 'styled-components';
import { RootState } from '@/store';
import { withQuery } from '../data/withQuery';
import { Metafields, Product, ProductTypes } from '@/types/ecommerce.types';
import {
  getCornerImage,
  getCurrentVariant,
  getUrlByColor,
  handleizeProductType,
  mapProductEdges,
} from '@/utils/utils';
import { ProductOptionsTitle } from './ProductOptions.styles';
import { colors } from '@/themes/colorsMapping';
import getProductDescription from '../shared/getProductDescription';

const StyledLink = styled(Link)<{ $availableToSell?: boolean }>`
  ${(props) =>
    !props.$availableToSell &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
  span {
    padding: 10px;
    padding-top: 0;
    padding-bottom: 7px;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
  }
`;
StyledLink.displayName = 'ColorSwatch';

const SwatchesList = styled.ul`
  padding: 3px;
  margin-top: 13px;
  position: relative;
  display: grid;
  list-style-type: none;
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  gap: 12px;

  @media (min-width: 800px) {
    margin-top: 10px;
  }

  li {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid ${colors.darkGray};
    &.tmplt-product__connected-collection-item--current {
      border-color: ${colors.purple};
      box-shadow: 0 0 0 3px rgba(51, 0, 204, 0.2);
    }
  }
  a {
    background-color: transparent !important;
    background: none !important;
    span {
      @media (max-width: 800px) {
        display: block;
        margin-top: -10px;
      }
    }
  }
  img {
    width: 190%;
    transform: translate(-20%, 0);
    height: 70px;
    object-position: bottom;
    object-fit: cover;
  }
`;

export type Color = {
  title: string;
  handle: string;
  color: string;
  colorName: string;
  availableToSell: boolean;
};

type PropTypes = {
  product: Product;
  colorCollection: Collection;
  className: string;
  pagePath?: string;
  showDefaultColor?: boolean;
  openStyle?: boolean;
  sectionTitle?: string;
  shopMetafields: Metafields;
};

const ProductImageSwatches = ({
  product,
  colorCollection,
  className,
  pagePath = '/products',
  showDefaultColor,
  openStyle,
  sectionTitle,
  shopMetafields,
}: PropTypes) => {
  const variants = product?.variants?.edges;
  const currentVariant = getCurrentVariant(variants);
  const productType = handleizeProductType(product?.productType);
  const frameSize = currentVariant?.selectedOptions[0].value;
  const { type, partner } = useSelector((state: RootState) => state.product);
  const colors: Color[] = mapProductEdges(colorCollection);
  const isGallery = type === ProductTypes.GALLERY;

  const images = colorCollection?.products?.edges.map((edge, index) =>
    getCornerImage(edge.node.images.edges)
  );

  const { color: productColor, printSize } = getProductDescription(product, shopMetafields);

  if (colors.length <= 1 && !showDefaultColor) {
    return null;
  }

  if (
    !product ||
    !currentVariant ||
    !colorCollection ||
    (productType !== ProductTypes.PICTURE &&
      productType !== ProductTypes.GALLERY &&
      productType !== ProductTypes.FRAMEDCANVAS &&
      productType !== ProductTypes.BLACKLABEL &&
      !showDefaultColor)
  ) {
    return null;
  }

  return (
    <div className={className}>
      {sectionTitle && (
        <ProductOptionsTitle>
          {sectionTitle}: {'\u00A0'}
          <span>{isGallery ? printSize : productColor}</span>
        </ProductOptionsTitle>
      )}
      <SwatchesList>
        {colors?.map((color, index) => {
          const url = getUrlByColor({
            pagePath,
            color: color.handle,
            productType,
            frameSize,
            openStyle,
            partner,
          });

          return (
            <li
              key={color.handle}
              className={`${
                product.handle === color.handle
                  ? 'tmplt-product__connected-collection-item--current'
                  : ''
              }`}
            >
              <StyledLink
                $availableToSell={color.availableToSell}
                to={url}
                onClick={(e) => {
                  if (!color.availableToSell) e.preventDefault();
                }}
                className={`color-${color?.colorName.toLowerCase()}${
                  !color?.availableToSell ? ' tmplt-product__variants-item--sold-out' : ''
                }`}
                style={{ backgroundColor: color?.color }}
                title={color.title}
              >
                <img src={images[index]} alt="" />
                <span>{color.colorName}</span>
              </StyledLink>
            </li>
          );
        })}
      </SwatchesList>
    </div>
  );
};

ProductImageSwatches.displayName = 'ProductImageSwatches';
export default withQuery(ProductImageSwatches);
