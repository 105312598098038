import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPartner } from '../store/product/productSlice';

const useSetupPartner = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const partnerParam = urlParams.get('partner');
  const partnerFromLS = localStorage.getItem('partner');
  const dispatch = useDispatch();

  useEffect(() => {
    const hasParnerClass =
      document.body.classList.contains('print-shop') || document.body.classList.contains('partner-') || process.env.NODE_ENV === 'development';
    if (partnerParam && hasParnerClass) {
      dispatch(setPartner(partnerParam));
      localStorage.setItem('partner', partnerParam);
    } else if (partnerFromLS && hasParnerClass) {
      dispatch(setPartner(partnerFromLS));
    }
  }, [partnerParam]);
};

export default useSetupPartner;
