import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Metafields, Product } from '@/types/ecommerce.types';
import { RootState } from '@/store';
import { withQueryOnlyData } from '../data/withQueryOnlyData';
import getProductDescription from '@/components/product/shared/getProductDescription';
import { getMetafieldV2 } from '@/utils/utils';

type PropTypes = {
  product: Product;
  shopMetafields?: Metafields;
  showSize?: boolean;
  className?: string;
};

const StyledH1 = styled.h1`
  font-size: 20px !important;
  text-align: center;
  font-weight: 600;
  display: block;
  margin: 0 auto;

  @media (min-width: 800px) {
    font-family: 'Termina';
    margin: 0;
    text-align: left;
    font-size: 32px !important;
    font-weight: 600;
    line-height: 120%;
  }
`;

const StyledHeader = styled.header`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0 !important;

  @media (min-width: 768px) {
    background-color: transparent;
    width: 100%;
    margin: 10px 0 0 0;
    align-items: flex-start;
  }

  img {
    max-width: 100px;
    max-height: 15px;
  }
`;

const AttributionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 3px 0 2px 0;
  font-size: 14px;
  @media (min-width: 768px) {
    margin: 3px 0 10px 0;
  }
  strong {
    font-size: 16px;
    font-weight: 500;
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
`;

const AttributionText = styled.span`
  margin-right: 6px;
  margin-top: -2px;
`;

const AttributionLogo = styled.img`
  height: 20px;
`;

const ProductHeaderV2 = ({ product, shopMetafields, showSize, className }: PropTypes) => {
  const { productTitle: productTitleFromRedux } = useSelector((state: RootState) => state.product);
  const { size: productSize } = getProductDescription(product, shopMetafields);
  const size = productSize && showSize ? productSize.split(' ')[0] : '';
  const attributionLogo = getMetafieldV2('attribution_logo', product?.metafields);
  const attributionText = getMetafieldV2('attribution_text', product?.metafields);
  const attributionPrefixLabel = getMetafieldV2('attribution_prefix_label', product?.metafields);
  const attributionLogoParsed = attributionLogo ? JSON.parse(attributionLogo) : null;

  return (
    <StyledHeader className={`tmplt-product__header ${className}`}>
      <StyledH1 data-testid="product-title">
        {size} {productTitleFromRedux || product?.title}
      </StyledH1>

      {(attributionLogo || attributionText) && (
        <AttributionWrapper>
          <AttributionText>{attributionPrefixLabel ?? 'Designed by'}</AttributionText>
          {attributionLogo ? (
            <AttributionLogo src={attributionLogoParsed[0]?.src} alt="Attribution Logo" />
          ) : (
            <strong>{attributionText}</strong>
          )}
        </AttributionWrapper>
      )}
    </StyledHeader>
  );
};

ProductHeaderV2.displayName = 'ProductHeaderV2';
export default React.memo(withQueryOnlyData(ProductHeaderV2));
